import { useCallback } from 'react';

import { Console, Grecaptcha } from '@sbiz/util-browser';

import { useConfigContext } from '../../../contexts/ConfigContext';

export function useGetCaptchaToken() {
  const {
    captcha: { siteKey },
  } = useConfigContext();

  const getToken = useCallback(
    (action: string) =>
      new Promise<string>((resolve, reject) => {
        const { grecaptcha } = globalThis as { grecaptcha?: Grecaptcha };

        if (grecaptcha?.enterprise) {
          try {
            grecaptcha.enterprise.ready(() => {
              grecaptcha.enterprise.execute(siteKey, { action }).then(resolve).catch(reject);
            });
          } catch (error) {
            reject(error);
          }
        } else {
          reject(new Error('Captcha could not be found'));
        }
      }),
    [siteKey],
  );

  return useCallback(
    async (action: string) => {
      try {
        const token = await getToken(action);
        return token;
      } catch (error) {
        Console.error(error);
      }
    },
    [getToken],
  );
}
