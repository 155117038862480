import { useCallback } from 'react';

import { getSessionStorageItem, setSessionStorageItem } from '@sbiz/util-browser';
import { sleep } from '@sbiz/util-common';
import { getJwtExp } from '@sbiz/util-jwt';

import { STORAGE_KEYS } from '../common';
import { getFreshTokens, getSession } from '../common/auth';

type Tokens = { accessToken: string; idToken: string; refreshToken: string };

type TokensCache = { status: 'idle'; value?: { expiresAt: number } & Tokens } | { status: 'error' | 'pending' };

export function useGetFreshTokens() {
  return useCallback(async function fetchTokens(depth = 0) {
    const cache = getSessionStorageItem<TokensCache>(STORAGE_KEYS.tokens);

    try {
      if (cache?.status === 'pending') {
        if (depth >= 30) {
          throw new Error();
        }

        await sleep(50);
        return fetchTokens(depth + 1);
      }

      if (cache?.status === 'idle' && (!cache?.value || cache.value.expiresAt > Date.now())) {
        return cache.value;
      }

      setSessionStorageItem(STORAGE_KEYS.tokens, { status: 'pending' }, { isOverwrite: true });
      const session = await getSession();
      const userId = session?.user?.id;

      const newCache: TokensCache = { status: 'idle' };

      if (userId) {
        const tokens = await getFreshTokens(userId);

        if (tokens) {
          const exp = getJwtExp(tokens.accessToken) as number;
          newCache.value = { ...tokens, expiresAt: (exp - 20) * 1_000 };
        }
      }

      setSessionStorageItem(STORAGE_KEYS.tokens, newCache, { isOverwrite: true });

      return newCache.value;
    } catch {
      setSessionStorageItem(STORAGE_KEYS.tokens, { status: 'error' }, { isOverwrite: true });
    }
  }, []);
}
