import { useCallback } from 'react';

import { PAGES } from '../common/pages';
import { useConfigContext } from '../contexts/ConfigContext';
import { useQueryParam } from './useQueryParam';
import { useRoute } from './useRoute';

export function useRedirectAfterLogout() {
  const {
    smood: { urls },
  } = useConfigContext();
  const [isLeavingParam, setIsLeavingParam] = useQueryParam('isLeaving');
  const isLeavingParamValue = Boolean(isLeavingParam?.value);
  const [, setRoute] = useRoute();

  return useCallback(
    (options?: { isLeaving?: boolean }) => {
      const isLeaving = typeof options?.isLeaving === 'boolean' ? options.isLeaving : isLeavingParamValue;

      if (isLeaving) {
        if (isLeavingParamValue) {
          setIsLeavingParam(undefined, { isShallow: true });
        }

        window.location.href = urls.showcase;
      } else {
        setRoute({ pathname: PAGES.login.path, searchParams: null });
      }
    },
    [isLeavingParamValue, setIsLeavingParam, setRoute, urls.showcase],
  );
}
