import { useCallback } from 'react';

import { ApiFetcherOptions, SafeApiResponse } from '../types';
import { useClearCache } from './useClearCache';
import { useGetApiError } from './useGetApiError';

export function useSafeApiFetcher() {
  const clearCache = useClearCache();
  const getApiError = useGetApiError();

  return useCallback(
    async <T>(apiCall: Promise<T> | (() => Promise<T>), options?: ApiFetcherOptions): Promise<SafeApiResponse<T>> => {
      try {
        const data = await (typeof apiCall === 'function' ? apiCall() : apiCall);

        if (options?.clear) {
          clearCache(options.clear, options.clearOptions);
        }

        return { data };
      } catch (error) {
        const apiError = getApiError(error);

        return { error: apiError };
      }
    },
    [clearCache, getApiError],
  );
}
