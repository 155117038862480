import { useCallback } from 'react';

import { PAGES } from '../common/pages';
import { useConfigContext } from '../contexts/ConfigContext';
import { useQueryParam } from './useQueryParam';

export function useRedirectToKeycloakLogout() {
  const {
    keycloak: {
      paths,
      urls: { external: keycloakUrl },
    },
  } = useConfigContext();
  const [isLeavingParam] = useQueryParam('isLeaving');
  const isLeavingParamValue = Boolean(isLeavingParam?.value);

  return useCallback(
    (idToken: string, options?: { isLeaving?: boolean }) => {
      const isLeaving = typeof options?.isLeaving === 'boolean' ? options.isLeaving : isLeavingParamValue;
      const redirectUri = `${window.origin}${PAGES.logoutSuccess.path}${isLeaving ? '?isLeaving=1' : ''}`;
      const searchParams = new URLSearchParams({ id_token_hint: idToken, post_logout_redirect_uri: redirectUri });

      window.location.href = `${keycloakUrl}/${paths.logout}?${searchParams}`;
    },
    [isLeavingParamValue, keycloakUrl, paths.logout],
  );
}
