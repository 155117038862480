import { useCallback } from 'react';

import { useGetFreshTokens } from './useGetFreshTokens';

export function useGetIdToken() {
  const getFreshTokens = useGetFreshTokens();

  return useCallback(async () => {
    const tokens = await getFreshTokens();
    return tokens?.idToken;
  }, [getFreshTokens]);
}
